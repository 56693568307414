// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-area-js": () => import("./../../../src/pages/area.js" /* webpackChunkName: "component---src-pages-area-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-index-banner-js": () => import("./../../../src/pages/index-banner.js" /* webpackChunkName: "component---src-pages-index-banner-js" */),
  "component---src-pages-index-blog-js": () => import("./../../../src/pages/index-blog.js" /* webpackChunkName: "component---src-pages-index-blog-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-particle-js": () => import("./../../../src/pages/index-particle.js" /* webpackChunkName: "component---src-pages-index-particle-js" */),
  "component---src-pages-index-slider-js": () => import("./../../../src/pages/index-slider.js" /* webpackChunkName: "component---src-pages-index-slider-js" */),
  "component---src-pages-no-index-js": () => import("./../../../src/pages/no-index.js" /* webpackChunkName: "component---src-pages-no-index-js" */),
  "component---src-pages-nosotros-js": () => import("./../../../src/pages/nosotros.js" /* webpackChunkName: "component---src-pages-nosotros-js" */),
  "component---src-pages-project-js": () => import("./../../../src/pages/project.js" /* webpackChunkName: "component---src-pages-project-js" */),
  "component---src-pages-trabaja-con-nosotros-js": () => import("./../../../src/pages/trabaja-con-nosotros.js" /* webpackChunkName: "component---src-pages-trabaja-con-nosotros-js" */),
  "component---src-templates-area-template-area-template-js": () => import("./../../../src/templates/area-template/area-template.js" /* webpackChunkName: "component---src-templates-area-template-area-template-js" */),
  "component---src-templates-blog-list-blog-list-js": () => import("./../../../src/templates/blog-list/blog-list.js" /* webpackChunkName: "component---src-templates-blog-list-blog-list-js" */),
  "component---src-templates-blog-template-blog-template-js": () => import("./../../../src/templates/blog-template/blog-template.js" /* webpackChunkName: "component---src-templates-blog-template-blog-template-js" */),
  "component---src-templates-project-template-project-template-js": () => import("./../../../src/templates/project-template/project-template.js" /* webpackChunkName: "component---src-templates-project-template-project-template-js" */)
}

